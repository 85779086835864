* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

#content:focus {
    outline: none;
}

ul {
    list-style-type: none;
    padding-inline-start: 0px;
}
