// Config
$asset-root: '/static/images/';
$fonts: '/static/fonts';
$fa-font-path: '/static/fonts';

// Icons
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';

// These are the bare minimum styles required to help
// use older great styles.
// When the new design system replaces some of the old great
// styles we should be able to remove these imports.
@import "node_modules/govuk-frontend/govuk/settings/all";
@import "node_modules/govuk-frontend/govuk/tools/all";

// We've been able to specifically import some of the
// helpers rather than all
@import "node_modules/govuk-frontend/govuk/helpers/spacing";
@import "node_modules/govuk-frontend/govuk/helpers/media-queries";
@import "node_modules/govuk-frontend/govuk/helpers/focused";
@import "node_modules/govuk-frontend/govuk/helpers/font-faces";
@import "node_modules/govuk-frontend/govuk/helpers/typography";
@import "node_modules/govuk-frontend/govuk/helpers/links";

@import "node_modules/govuk-frontend/govuk/core/links";
@import "node_modules/govuk-frontend/govuk/core/lists";



@import 'node_modules/great-styles/src/scss/helpers/_base.scss';
@import '../../styles/great/utils';
@import '../../core/sass/helpers/utils.scss';
@import '../../styles/great/_components.scss';

// Great Widgets
@import '../../styles/widgets/buttons.scss';
@import '../../styles/widgets/cookies-modal.scss';

// Great Components
@import '../../core/components/sass/components/skip-link/_base.scss';

// Great International
@import 'core-class-overrides';
@import 'reset.scss';
@import 'utils.scss';

// Great International pages
@import 'pages/index.scss';

// Great International components
@import 'hero.scss';

/* EYB article tempory overrides to mimic govuk typography styles */
.image-container {
    aspect-ratio: 5 / 2;
    width: 100%;
    height: auto;
}

.block-text + hr {
    display: none;
}

.block-text {
    a {
        text-decoration-thickness: max(1px, .0625rem);
        text-underline-offset: .25em;
    }
    a:hover {
        color:#003078;
        text-decoration-thickness: max(3px, .1875rem, .12em);
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        text-decoration-skip: none;
    }
    h2 {
        font-size: 1.6875rem;
        line-height: 1.296295;
        padding-top: 15px;
        margin-bottom: 20px;
    }
    h3 {
        font-size: 1.3125rem;
        line-height: 1.42857;
    }
    h4 {
        font-size: 1.125rem;
        line-height: 1.38889;
    }
    h3, h4, h5 {
        margin-bottom: 15px;
    }
    p, ul, ol {
    + h3, + h4, + h5 {
            padding-top: 5px;
        }
    }
    p, ul, ol {
        margin-bottom: 15px;
        line-height: 1.3888888889;
    }
    ol, ul {
        padding-left: 20px;
    }
    ul {
        list-style-type: disc;

        li {
            display: list-item;
            text-align: -webkit-match-parent;
            unicode-bidi: isolate;
        }
    }
    hr {
        display: none;
    }
}

@media (min-width: 40.0625em) {
    .block-text {
        h2 {
            font-size: 2.25rem;
            line-height: 1.25;
            padding-top: 20px;
            margin-bottom: 30px;
        }
        h3 {
            font-size: 1.5rem;
            line-height: 1.25;
        }
        h3, h4, h5 {
            margin-bottom: 20px
        }
        p, ul, ol {
            + h3, + h4, + h5 {
                padding-top: 10px;
            }
        }
        p, ul, ol {
            margin-bottom: 20px;
        }
        li {
            margin-bottom: 5px;
        }
    }
}

.autocomplete {
    &__input, &__option {
        font-size: 19px;
        line-height: 25px;
    }

    &__input {
        height: 40px;
    }
}
