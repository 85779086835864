.image-container {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.autocomplete__input {
    // font-family: $govuk-font-family;
}

.autocomplete__option {
    // font-family: $govuk-font-family;
}

.autocomplete__option:hover {
    .eyb-sector-lookup-second-row {
        color: white;
    }
}

.autocomplete__option:focus {
    color: white;
    .eyb-sector-lookup-second-row {
        color: white;
    }
}

.autocomplete__option--odd {
    background-color: $great-light-blue;
}

.autocomplete__option--odd:hover {
    background-color: #1d70b8;
    .eyb-sector-lookup-second-row {
        color: white;
    }
}

.autocomplete__option--odd:focus {
    background-color: #1d70b8;
    color: white;
    .eyb-sector-lookup-second-row {
        color: white;
    }
}

.govuk-fieldset__heading {
    line-height: inherit;
}
