.great-interntional-centered-content {
    text-align: center;
}

.light-blue-background {
    background-color: $great-light-blue;
}

.light-green-background {
    background-color: #8CE2D0;
}

.white-background {
    background-color: #FFFFFF;
}

.fixed-height-scroll {
    max-height: 300px;
    overflow-x: scroll;
}

.fixed-height-scroll.govuk-checkboxes--small .govuk-checkboxes__item {
    float: none;
}

.js-enabled .govuk-accordion__section-content .fixed-height-scroll {
    margin-top: -20px;
    margin-bottom: -20px;
}

.js-enabled .govuk-accordion__section-button .fixed-height-scroll {
    border-bottom: 0px;
}

.override-negative-margin {
    .govuk-grid-row {
        margin-left: 0px;
        margin-right: 0px
    }
}

.no-max-width {
    max-width: none !important;
}
